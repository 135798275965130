import '@hotwired/turbo-rails'

document.addEventListener('turbo:submit-start', event => {
  event.target.classList.add('loading')
}, true)

document.addEventListener('turbo:submit-end', event => {
  event.target.classList.remove('loading')
}, true);

// Debug Turbo events
[
  'click',
  'before-visit',
  'visit',
  'submit-start',
  'before-fetch-request',
  'before-fetch-response',
  'submit-end',
  'before-cache',
  'before-render',
  'before-stream-render',
  'render',
  'load'
].forEach(event => {
  document.addEventListener(`turbo:${event}`, e => {
    if (window.debug) console.log(`turbo:${event}`, e)
  }, { capture: true })
})
